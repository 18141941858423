
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, StockTransCard } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import moment from 'moment';
import { nestedGroupBy } from '@/util/nestedGroupBy';
import gdbx from '@/store/modules/gdbx';
@Component({
	components: {
		TableFilterable,
	},
})
export default class StockItemSalesMovementTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['stocks'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange', 'stockItems', 'agents'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];
	public result: any[] = [];

	public get exportFileName() {
		const formattedDate = [
			moment(this.selectedDateRange[0]).format('DD MMM YY'),
			moment(this.selectedDateRange[1]).format('DD MMM YY'),
		];
		return (
			'Stock Item Movement' + '_' + formattedDate[0] + ' to ' + formattedDate[1]
		);
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get expensiveHook() {
		const { selectedDateRange, selectedAgents, selectedStockItems } = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('stockMovement')
			.agents(this.selectedAgents)
			.stockItems(this.selectedStockItems);

  const stockMovement = await ref.get();
  const stockMovementCards = await stockMovement.getCards();

  const nestedArray = nestedGroupBy(stockMovementCards.filter((a) =>
      a.postDate > this.selectedDateRange[0]
          && a.postDate < this.selectedDateRange[1],
      ),
      ['itemCode']);

  const newArray: any [] = [];

  for (const item of Object.keys(nestedArray)) {
      const lastItem = nestedArray[item].slice(-1);
      newArray.push(
          {
            item_code: item,
            item_description: lastItem[0].description,
            cost_in: nestedArray[item].reduce(((a, b) => a + b.costIn), 0),
            price_out: nestedArray[item].reduce(((a, b) => a + b.priceOut), 0),
            quantity_in: nestedArray[item].reduce(((a, b) => a + b.quantityIn), 0),
            quantity_out: nestedArray[item].reduce(((a, b) => a + Math.abs(b.quantityOut)), 0),
            balance_quantity: nestedArray[item].reduce(((a, b) => a + b.balanceQuantity), 0),
          },
      );
    }

  this.tableItems = newArray;

		// const stockTransCards = (await (
		// 	await ref.get()
		// ).getCards()) as StockTransCard[];
    //
		// const groupedCards = nestedGroupBy(stockTransCards, ['itemCode']);
    //
		// this.result = [];
    //
		// for (const stockItem in groupedCards) {
		// 	if (groupedCards.hasOwnProperty(stockItem)) {
		// 		const object = {
		// 			item_code: stockItem,
		// 			item_description: '',
		// 			cost_in: 0,
		// 			price_out: 0,
		// 			quantity_in: 0,
		// 			quantity_out: 0,
		// 			balance_quantity: 0,
		// 		};
    //
		// 		const itemObjects = groupedCards[stockItem] as StockTransCard[];
    //
		// 		object.item_description = gdbx.stockItemNames[itemObjects[0].itemCode];
		// 		object.cost_in = itemObjects.reduce((a, b) => a + b.amountIn, 0);
		// 		object.price_out = itemObjects.reduce((a, b) => a + b.amountOut * -1, 0);
		// 		object.quantity_in = itemObjects.reduce((a, b) => a + b.quantityIn, 0);
		// 		object.quantity_out = itemObjects.reduce(
		// 			(a, b) => a + b.quantityOut * -1,
		// 			0,
		// 		);
		// 		object.balance_quantity = itemObjects.reduce(
		// 			(a, b) => a + b.quantity,
		// 			0,
		// 		);
    //
		// 		this.result.push(object);
		// 	}
		// }
    //
    //
		this.tableFields = [
			{ key: 'item_code', sortable: true, stickyColumn: true },
			{ key: 'item_description', sortable: true },
			{
				key: 'cost_in',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'price_out',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			{
				key: 'quantity_in',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
			{
				key: 'quantity_out',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
			{
				key: 'balance_quantity',
				sortable: true,
				formatter: TableItemFormatter.basic,
			},
		];
    //
		// this.tableItems = this.result.filter(
		// 	(item) =>
		// 		item.cost_in !== 0 ||
		// 		item.price_out !== 0 ||
		// 		item.quantity_in !== 0 ||
		// 		item.quantity_out !== 0 ||
		// 		item.balance_quantity !== 0,
		// );

		this.saveHistory('tableItems', 'tableFields');
	}

  public async created() {
    const movement = await FilteredDatabase.ref('stockMovement').get();
    const movementCards = await movement.getCards();
  }
}
