
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Page from './Page.vue';
import StockItemMovementTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemMovementTable.vue';
import StockItemSalesByCustomerTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemSalesByCustomerTable.vue';
import StockItemPurchaseBySupplierTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemPurchaseBySupplierTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import StockGroupSalesPurchaseBarChart from '@/components/reports-v2/components/codedWidgets/stock/StockGroupSalesPurchaseBarChart.vue';

@Component({
	components: {
		StockItemMovementTable,
		StockItemSalesByCustomerTable,
		StockItemPurchaseBySupplierTable,
		CustomerListFilter,
		StockGroupSalesPurchaseBarChart,
	},
})
export default class Page2 extends Page {
	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}
}
