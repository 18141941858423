
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { GobiColor } from '@/helpers/color';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import SwitchAmountQuantityToggle from '@/components/SwitchAmountQuantityToggle.vue';

@Component({
	components: {
		StackedBarChartFilterable,
		IncludeTaxToggle,
		SelectFilter,
		SwitchAmountQuantityToggle,
	},
})
export default class StockGroupSalesPurchaseBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['stocks', 'sales', 'purchases'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'defaultStockGroup'
		| 'locations'
	> = ['dateAsOf', 'stockItems', 'locations'];

	// public legends = ['Total Sales', 'Cost', 'Profit'];
	public switchAmountQuantity: string = 'amount';

	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | any = [];

	public triggerSwitchAmountQuantity(value) {
		this.switchAmountQuantity = value;
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get allStockGroup() {
		return Object.keys(gdbx.stockGroupNames);
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedStockItems,
			switchAmountQuantity,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedStockItems,
			switchAmountQuantity,
		]);
	}

	public async expensiveCalc() {
		const salesRef = FilteredDatabase.ref('sales')
			.dateRange(this.selectedDateRange)
			.stockItems(this.selectedStockItems);

		const purchaseRef = FilteredDatabase.ref('purchases')
			.dateRange(this.selectedDateRange)
			.stockItems(this.selectedStockItems);

		let pa = 0.5;
		let pb = 0;

		const salesDatas: DimensionData[] = await this._loadDimensionByFilters(
			salesRef,
			'stockGroup',
			pa,
			pb,
			this.switchAmountQuantity === 'amount' ? 'amount' : 'quantity',
		);

		pb += pa;
		pa = 0.5;

		const purchaseDatas: DimensionData[] = await this._loadDimensionByFilters(
			purchaseRef,
			'stockGroup',
			pa,
			pb,
			this.switchAmountQuantity === 'amount' ? 'amount' : 'quantity',
		);

		this.xAxisData = salesDatas.map((dd) => dd.text as string);

		const salesResult = {
			name: 'Total Sales',
			type: 'bar',
			barWidth: '30%',
			color: GobiColor.COLOR1,
			data: salesDatas.map((item) => item.sum),
		};

		const purchaseResult = {
			name: 'Total Purchase',
			type: 'bar',
			barWidth: '30%',
			color: GobiColor.COLOR3,
			data: purchaseDatas.map((item) => item.sum),
		};

		this.series = [salesResult, purchaseResult];

		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
